import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { logoutUser } from "../features/authSlice";

// const BASE_URL = "http://localhost";
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("accessToken");
    // const token = getState().auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // If expired, refresh token
    if (result.error.data && result.error.data.error_code === "TOKEN_EXPIRED") {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            {
              url: "admin/v1/refresh",
              method: "POST",
            },
            api,
            extraOptions
          );
          if (refreshResult.data) {
            // api.dispatch(tokenReceived(refreshResult.data));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
          } else {
            // api.dispatch(loggedOut());
            api.dispatch(logoutUser());
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      api.dispatch(logoutUser());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "Account",
    "Quotes",
    "Attendances",
    "Support-Metrics",
    "Support-Logs",
    "Emotional-Logs",
    "Emotional-Types",
    "Employments",
    "Pro-Schedules",
    "Workplaces",
    "Candidates",
    "PWD-Status",
    "Introduction-Forms",
    "Assessment-Forms",
    "Sexes",
    "Religions",
    "InteractionTypes",
    "MedicalTypes",
    "Races",
    "UserGoals",
    "UserGoalsProgress",
    "EmotionsConfig",
    "EducationLevels",
    "AppUsers",
    "Users",
    "User",
    "Logs",
    "Organisations",
    "Organisation",
    "OrganisationLogs",
    "OrgContactRoles",
    "OrganisationContacts",
    "OrganisationSupportingDocs",
    "OrgSupportingDocTypes",
    "OpportunityTypes",
    "OpportunityContractTypes",
    "OpportunityCommitments",
    "OrganisationTOBs",
    "OrganisationBillables",
    "Permissions",
    "Roles",
    "MetricSoftSkills",
    "MetricHardSkills",
    "MetricMotorSkills",
    "MetricOtherSkills",
    "ObservationGradings",
    "ChatUsers",
    "Intents",
    "IntentExamples",
    "Utterances",
    "UtteranceResponses",
    "Stories",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
